<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Heading-->
    <div
      class="
        form
        w-100
        fv-plugins-bootstrap5 fv-plugins-framework
        text-center
        mb-5
      "
    >
      <!--begin::Title-->
      <h1 class="text-dark mb-3">Forgot Password Confirmation</h1>
      <!--end::Title-->

      <!--begin::Link-->
      <div class="text-gray-400 fw-bold fs-4 mb-3">
        Please check your email to reset your password.
      </div>
      <router-link
        :to="{
          name: 'sign-in',
        }"
        class="btn btn-sm btn-flex btn-light-primary"
      >
        <span class="svg-icon svg-icon-3">
          <inline-svg src="/media/icons/duotune/arrows/arr002.svg" />
        </span>
        Back to Sign-In</router-link
      >
      <!--end::Link-->
    </div>
    <!--begin::Heading-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "forgot-password-confirmation",
  setup() {
    return {};
  },
});
</script>
